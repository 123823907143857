import { DefaultLayout } from '../layouts/DefaultLayout';
import { Hero } from '../components/Hero';
import { AboutUsPanels } from '../components/About-Us-Panels';
import { StaticImage } from 'gatsby-plugin-image';
import { Seo } from '../components/seo';

const breadCrumbNames = ['About Us'];
const breadCrumbUrls = ['/about-us/'];

const AboutUs: Page = () => {
    return (
        <>
            <Seo
                title="About Innovative Medicine in Lafayette, IN"
                description="Innovative Medicine provides several types of treatments. Innovative Medicine is based in Lafayette IN.Our team providing best natural medical care services."
            />
            <Hero
                title="About Us"
                breadCrumbUrls={breadCrumbUrls}
                breadCrumbNames={breadCrumbNames}
            >
                {' '}
                <StaticImage
                    className=""
                    src="../images/in-office.jpeg"
                    placeholder="none"
                    quality={100}
                    alt="ladyin office "
                />
            </Hero>
            <AboutUsPanels />
        </>
    );
};
AboutUs.Layout = DefaultLayout;

export default AboutUs;
