import React from 'react';
import styled from '@emotion/styled';
import { ServicePanel } from '../components/layout/Service-Panel';
import { StaticImage } from 'gatsby-plugin-image';
import { device } from './layout/GlobalStyles';

const StyledAboutUsPanels = styled.div`
    margin-top: 36px;
    > div {
        margin-bottom: 32px;
    }
    @media ${device.tablet} {
        margin-top: 72px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }
`;

export const AboutUsPanels: React.FC = () => {
    return (
        <StyledAboutUsPanels className="container">
            <ServicePanel url="/about-us/meet-our-team/" service="Meet Our Team">
                <StaticImage
                    src="../images/team-group-shot.jpeg"
                    alt="See our Innovative Medicine team"
                    quality={100}
                    placeholder="none"
                />
            </ServicePanel>{' '}
            <ServicePanel url="/about-us/testimonials/" service="Testimonials">
                <StaticImage
                    src="../images/woman-shaking-hand.jpeg"
                    alt="Woman shaking hand"
                    quality={100}
                    placeholder="none"
                />
            </ServicePanel>
            <ServicePanel url="/about-us/intake-forms/" service="Intake Forms">
                <StaticImage
                    src="../images/intake-forms.jpeg"
                    alt="hand with pen filling on a form"
                    quality={100}
                    placeholder="none"
                />
            </ServicePanel>
        </StyledAboutUsPanels>
    );
};
